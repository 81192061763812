import * as React from "react"
import Layout from "components/layout"
import PageTitle from "components/PageTitle"

const pageStyle = {
  margin: `50px 18vw`
}

// markup
const NotFoundPage = () => {
  return (
    <Layout>
        <PageTitle title="404 - Page not found"/>
        <section style={pageStyle} >
          <p>Sorry the page you were searching for could not be found! :(</p>
        </section>
    </Layout>
  )
}

export default NotFoundPage
